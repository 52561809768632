import { Link } from 'gatsby';
import React from 'react'
import { truncate } from 'lodash';
import remark from 'remark'
import strip from 'strip-markdown';
import { getImage } from '../util'
import { formatDate } from '../util/events';

const PostSummary = ({ post, cellSize = "small-12 medium-4" }) => {
    const getSummary = (summary, content) => {
        content = remark()
            .use(strip)
            .processSync(content)
            .toString();
        return summary && summary !== "" ? summary : truncate(content, { 'length': 150, 'omission': '\u2026' })
    }

    return (
        <div className={`cell ${cellSize} post-tile`}>
            {post.fields && post.featuredImage &&
                (<Link to={post.fields.slug} tabIndex="-1">
                    <figure>
                        {getImage(post.featuredImage, post.title)}
                    </figure>
                </Link>)
            }
            <p className="post-date">{formatDate(post.publishedOn)}</p>
            {post.fields &&
                (<Link className="h5" to={post.fields.slug}>
                    {post.title}
                </Link>)
            }
            <p>{getSummary(post.summary, post.content)}</p>
        </div>
    )
}

export default PostSummary