import { graphql, Link } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { min, range } from 'lodash';
import MediaQuery from 'react-responsive'

import Layout from '../components/layout';
import PostSummary from '../components/PostSummary';


export default class BlogPage extends React.Component {

    postTiles = (posts) => posts.map(({ node: post }) => (
        <PostSummary key={post.id} post={post} />
    ))

    render() {
        const { data } = this.props

        if (!data || !data.posts) return null;
        let { edges: posts } = data.posts

        const { currentPage, numPages } = this.props.pageContext
        const isFirst = currentPage === 1
        const isLast = currentPage === numPages
        const prevPage = `/news/${currentPage - 1 === 1 ? "" : (currentPage - 1).toString()}`
        const nextPage = `/news/${(currentPage + 1).toString()}`
        /*
                let start = currentPage > 5 ? currentPage - 4 : 1
                let end = min([currentPage + 4, numPages]) + 1
                let pages = range(start, end)
        */
        let start = currentPage <= 3 ? 1 : currentPage < (numPages - 2) ? currentPage - 2 : numPages - 4;
        let end = currentPage <= 3 ? min([6, numPages + 1]) : currentPage < (numPages - 2) ? currentPage + 3 : numPages + 1;
        let pages = start === end ? [1] : range(start, end);

        return (
            <Layout>
                <section className="section grid-container">
                    <Helmet title={`News ${currentPage !== 1 ? `Page ${currentPage} ` : ''}| Fluxible: Canada’s UX Festival`} />
                    <h1 className="page-header">Fluxible News</h1>
                    <div className="grid-x grid-margin-x">
                        {this.postTiles(posts)}
                    </div>
                    <MediaQuery minWidth="40rem">
                        <nav aria-label="Pagination" className="blog-pagination">
                            <div className="previous-link">
                                {isFirst ?
                                    (<span aria-hidden="true"></span>) :
                                    <Link to={prevPage} rel="prev" aria-label="Previous page">
                                        Previous
                                    </Link>
                                }
                            </div>
                            <div> {(start !== 1) && '...'}</div>
                            <ol className="page-list">
                                {Array.from(pages, i => (
                                    (currentPage === i) ?
                                        (<li className="current" key={`blogPagination${i}`} aria-label={`Current page, page ${i}`}><span>{i}</span></li>) :
                                        (<li key={`blogPagination${i}`}><Link key={`pagination-number${i}`} to={`/news/${i === 1 ? '' : i}`} aria-label={`Go to page ${i}`}>{i}</Link></li>)
                                ))}
                            </ol>
                            <div>{(end !== numPages + 1) && "..."}</div>
                            <div className="next-link">
                                {isLast ?
                                    (<span aria-hidden="true"></span>) :
                                    <Link to={nextPage} rel="next" className="next-link" aria-label="Next page">
                                        Next
                                    </Link>
                                }
                            </div>
                        </nav>
                    </MediaQuery>
                    <MediaQuery maxWidth="40rem">
                        <nav aria-label="Pagination" className="mobile-blog-pagination">
                            <div className="previous-link">
                                {isFirst ?
                                    (<span aria-hidden="true"></span>) :
                                    <Link to={prevPage} rel="prev" aria-label="Previous page">
                                        <i className="fa fa-chevron-left left-chevron" aria-hidden="true" />
                                        Previous
                                    </Link>
                                }
                            </div>
                            <div className="next-link">
                                {isLast ?
                                    (<span aria-hidden="true"></span>) :
                                    <Link to={nextPage} rel="next" className="next-link" aria-label="Next page">
                                        Next
                                        <i className="fa fa-chevron-right right-chevron" aria-hidden="true" />
                                    </Link>
                                }
                            </div>
                        </nav>
                    </MediaQuery>

                </section>
            </Layout>
        )
    }
}

BlogPage.propTypes = {
    data: PropTypes.shape({
        posts: PropTypes.shape({
            edges: PropTypes.array,
        }),
    }),
}

export const blogPageQuery = graphql`
query BlogPageQuery($skip: Int!, $limit: Int!, $editionTypeId: Int!) {
    posts: allFluxibleBlogPosts(
                filter:{title:{ne:""}, editionTypeId: {eq: $editionTypeId}}, 
                sort: {fields: [publishedOn], order: DESC},
                limit: $limit,
                skip: $skip
        ) {
        edges {
          node {
            id
            title
            summary
            content
            publishedOn
            fields {
                slug
            }
            featuredImage {
                name
                altText
                contentType
              }
          }
        }
    }
  }
`
